import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "config/firebase";
import { useUser } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [uid, setUid] = useState<string>();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // const uid = user?.uid;
      setUid(user?.uid);
    });
  });
  const { isFetchingUser, user, updateUser } = useUser(uid);

  return (
    <div className="fixed h-16 md:h-20 top-0 w-full z-10">
      <div className="absolute  h-full w-full flex justify-between gap-3 items-center px-2 md:px-6 bg-base-2 text-white">
        <Link to="/" className="h-16 flex items-center gap-1 md:text-2xl">
          <svg
            className="w-12 h-12"
            width="550"
            height="550"
            viewBox="0 0 550 550"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_i_0_1)">
              <circle
                cx="275"
                cy="275"
                r="250"
                stroke="#F21B3F"
                strokeWidth="50"
              />
            </g>
            <g filter="url(#filter1_i_0_1)">
              <circle
                cx="275.25"
                cy="275.25"
                r="156.25"
                stroke="#29BF12"
                strokeWidth="50"
              />
            </g>
            <g filter="url(#filter2_i_0_1)">
              <circle
                cx="274.5"
                cy="274.5"
                r="62.5"
                stroke="#FF9914"
                strokeWidth="50"
              />
            </g>
            <defs>
              <filter
                id="filter0_i_0_1"
                x="0"
                y="0"
                width="554"
                height="554"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_0_1"
                />
              </filter>
              <filter
                id="filter1_i_0_1"
                x="94"
                y="94"
                width="366.5"
                height="366.5"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_0_1"
                />
              </filter>
              <filter
                id="filter2_i_0_1"
                x="187"
                y="187"
                width="179"
                height="179"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_0_1"
                />
              </filter>
            </defs>
          </svg>
          TRIO
        </Link>
        <div className="flex gap-2 md:gap-4 text-sm md:text-lg">
          <Link to="/online/settings" className="text-center">
            Play Online
          </Link>
          <Link to="/offline/room" className="text-center">
            Play Offline
          </Link>
          <Link to="/rules" className="text-center">
            Game Rules
          </Link>
        </div>
        {user ? (
          <Link to={`/user/${uid}`} className="text-center">
            {user.displayName || <span>My acount</span>}
          </Link>
        ) : (
          // <Link to="/">User</Link>
          <Link to="/login">Login</Link>
        )}
      </div>
    </div>
  );
};

export default Navbar;
