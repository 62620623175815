import React, { StrictMode, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "components/navbar";
import "./output.css";

const Home = lazy(() => import("pages/home"));
const Room = lazy(() => import("pages/room"));
const Rules = lazy(() => import("pages/rules"));
const Login = lazy(() => import("pages/login"));
const User = lazy(() => import("pages/user"));
const OfflineSettings = lazy(() => import("pages/offlineSettings"));
const OfflineRoom = lazy(() => import("pages/offlineRoom"));
const OnlineSettings = lazy(() => import("pages/onlineSettings"));
const Loading = lazy(() => import("pages/loading"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Navbar />
        <div className="pt-16 md:pt-20 w-full min-h-screen flex items-center justify-center bg-base-1 text-center text-white">
          <Routes>
            <Route path="/room/:id" Component={Room} />
            <Route path="/login" Component={Login} />
            <Route path="/loading" Component={Loading} />
            <Route path="/user/:uid" Component={User} />
            <Route path="/offline/settings" Component={OfflineSettings} />
            <Route path="/offline/room" Component={OfflineRoom} />
            <Route path="/online/settings" Component={OnlineSettings} />
            <Route path="/rules" Component={Rules} />
            <Route path="/" Component={Home} />
          </Routes>
        </div>
      </Suspense>
    </BrowserRouter>
  </StrictMode>
);
