export type Size = "large" | "medium" | "small";
export const colors: Color[] = ["red", "green", "blue", "purple"];
export type Color = "red" | "green" | "blue" | "purple";
export type Circle = { color: Color | undefined; winner: boolean };
export type SquareCircles = {
  large: Circle;
  medium: Circle;
  small: Circle;
};
export type Player = {
  player: string | undefined | "BOT";
  color: Color | undefined;
  large: number;
  medium: number;
  small: number;
  winns: number;
};
export type Players = { [key: string]: Player };
export interface RoomType {
  playersNumber: number;
  players: Players;
  currentPlayer: string;
  userPlayers: Array<string>;
  users: Array<string>;
  squares: Array<Array<SquareCircles>>;
  winner: string;
  gameOver: boolean;
}
export interface UserType {
  displayName: string;
}
export interface RuleType {
  description: string;
  squares: Array<Array<SquareCircles>>;
}
