import { db } from "config/firebase";
import { get, onValue, ref, set } from "firebase/database";
import { useEffect, useState } from "react";
import { UserType } from "typings";

interface Output {
  isFetchingUser: boolean;
  user?: UserType;
  updateUser: Function;
}

const useUser = (id: string | undefined): Output => {
  const [user, setUser] = useState<UserType | undefined>();
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(true);
  const userRef = ref(db, `users/${id}`);

  useEffect(() => {
    get(userRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setUser(snapshot.val());
        } else {
          console.log("User not found");
        }
        setIsFetchingUser(false);
      })
      .catch((error) => {
        console.error(error);
      });

    onValue(userRef, (snapshot) => {
      setUser(snapshot.val());
    });
  }, [id]);

  return {
    isFetchingUser,
    user,
    updateUser: (data: UserType) => {
      set(userRef, {
        ...data,
      });
    },
  };
};

export default useUser;
