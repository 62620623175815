import { db } from "config/firebase";
import { get, ref, set } from "firebase/database";
import { colors } from "typings";

function generateNewId() {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (let i = 0; i < 4; i++) {
    result += characters.charAt(Math.floor(Math.random() * 26));
  }
  return result;
}

const createRoom = (playersNumber: number, uid: string): string => {
  let newIdGenerated: boolean = false;
  let newId: string = generateNewId();
  while (!newIdGenerated) {
    const foundRoom = ref(db, `rooms/${newId}`);
    newIdGenerated = true;
    get(foundRoom)
      .then((snapshot) => {
        if (snapshot.exists()) {
          newIdGenerated = false;
          newId = generateNewId();
          console.log("room already exists");
        } else {
          newIdGenerated = true;
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const p: {} = {
    [uid]: {
      player: uid,
      color: colors[0],
      large: 3,
      medium: 3,
      small: 3,
      winns: 0,
    },
  };
  set(ref(db, "rooms/" + newId), {
    playersNumber: playersNumber,
    userPlayers: [uid],
    users: [uid],
    players: p,
    currentPlayer: uid,
    squares: Array(3).fill(
      Array(3).fill({
        large: {
          color: null,
          winner: false,
        },
        medium: {
          color: null,
          winner: false,
        },
        small: {
          color: null,
          winner: false,
        },
      })
    ),
    winner: "",
    gameOver: false,
  });
  return newId;
};

export default createRoom;
