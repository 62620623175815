import { db } from "config/firebase";
import { get, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { RuleType } from "typings";

interface Output {
  isFetching: boolean;
  rules?: Array<RuleType>;
}

const useRules = (): Output => {
  const rulesRef = ref(db, `rules`);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [rules, setRules] = useState<Array<RuleType> | undefined>();

  useEffect(() => {
    get(rulesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setRules(snapshot.val());
        } else {
          console.log("Rules not found");
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.error(error);
      });

    onValue(rulesRef, (snapshot) => {
      setRules(snapshot.val());
    });
  }, [isFetching]);

  return { isFetching, rules };
};

export default useRules;
