import { db } from "config/firebase";
import { get, onValue, ref, set } from "firebase/database";
import { useEffect, useState } from "react";
import { RoomType } from "typings";

interface Output {
  isFetching: boolean;
  room?: RoomType;
  updateRoom: Function;
}

const useRoom = (id: string | undefined): Output => {
  const [room, setRoom] = useState<RoomType | undefined>();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const roomRef = ref(db, `rooms/${id}`);

  useEffect(() => {
    get(roomRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setRoom(snapshot.val());
        } else {
          console.log("Room not found");
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.error(error);
      });

    onValue(roomRef, (snapshot) => {
      setRoom(snapshot.val());
    });
  }, [id]);

  return {
    isFetching,
    room,
    updateRoom: (data: RoomType) => {
      set(roomRef, {
        ...data,
      });
    },
  };
};

export default useRoom;
